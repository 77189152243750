function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
var GlobalSearch = {
  searchOptions: searchOptions,
  // eslint-disable-line
  tomSelectLookup: [],
  inputSelector: '.wrapper-search-autocomplete input.search-field',
  submitSelector: '.wrapper-search-autocomplete button[type=submit]',
  baseUrl: "".concat(location.protocol, "//").concat(location.host),
  redirectUrl: '',
  fuseOptions: {
    keys: [{
      name: "Title",
      weight: 0.5
    }, {
      name: "Tags",
      weight: 0.1
    }, {
      name: "ManualTags",
      weight: 0.6
    }],
    minMatchCharLength: 2,
    threshold: 0.2,
    distance: 500
  },
  init: function init() {
    var _this = this;
    var storeJsonIndexKey = "searchJsonIndex_".concat(this.searchOptions.language);
    var storeIndexKey = "searchIndex_".concat(this.searchOptions.language);
    var storeVersionKey = "searchIndexVersion_".concat(this.searchOptions.language);
    var storeSearchIndexVersion = this.getWithExpiry(storeVersionKey);
    if ("undefined" !== typeof storeSearchIndexVersion && this.searchOptions.autocompleteSearchVersion === storeSearchIndexVersion) {
      this.jsonIndex = this.getWithExpiry(storeJsonIndexKey);
      this.fuseIndex = Fuse.parseIndex(this.getWithExpiry(storeIndexKey));
      this.initFuseSearchEngine();
    } else {
      fetch("/wp-json/search/index/".concat(this.searchOptions.language)).then(function (response) {
        return response.json();
      }).then(function (jsonIndex) {
        _this.jsonIndex = jsonIndex;
      }).then(function () {
        _this.fuseIndex = Fuse.createIndex(_this.fuseOptions.keys, _this.jsonIndex);
      }).then(function () {
        _this.setWithExpiry(storeJsonIndexKey, _this.jsonIndex, 604800000);
        _this.setWithExpiry(storeIndexKey, _this.fuseIndex, 604800000);
        _this.setWithExpiry(storeVersionKey, _this.searchOptions.autocompleteSearchVersion, 604800000);
      }).then(function () {
        _this.initFuseSearchEngine();
      }).catch(function (error) {
        console.log("error: ", error);
      });
    }
  },
  initFuseSearchEngine: function initFuseSearchEngine() {
    if (this.jsonIndex && this.fuseIndex) {
      this.fuseEngine = new Fuse(this.jsonIndex, this.fuseOptions, this.fuseIndex);
      this.initSearchFields();
    }
  },
  fireSearch: function fireSearch() {
    var _this2 = this;
    setTimeout(function () {
      //adding timeout to let the time for the hook `item_add` to be triggered
      if (_this2.redirectUrl.length > 0) {
        window.location.href = _this2.redirectUrl;
      }
    }, 600);
  },
  initSearchFields: function initSearchFields() {
    var _this3 = this;
    document.querySelectorAll(this.inputSelector).forEach(function (searchInput) {
      var currentTomSelect = new TomSelect(searchInput, {
        maxItems: 1,
        maxOptions: 5,
        valueField: 'url',
        labelField: 'Title',
        searchField: 'Title',
        optionClass: 'search-dropdown__item',
        itemClass: 'search-field__item',
        create: true,
        createOnBlur: true,
        hideSelected: true,
        persist: false,
        closeAfterSelect: true,
        addPrecedence: true,
        //"Search ###" option is selected by default
        plugins: ['restore_on_backspace'],
        shouldLoad: function shouldLoad(query) {
          if (query.length < 2) {
            return false;
          }
          return true;
        },
        load: function load(query, callback) {
          console.log('load', query);
          var fuseResults = _this3.fuseEngine.search(query);
          console.log('fuseResults', fuseResults);
          var fuseResultsToReturn = [];
          fuseResults.forEach(function (fuseResult) {
            /* if fuseResult.item.UrlToLink */
            if (fuseResult.item.UrlToLink) {
              fuseResult.item.url = fuseResult.item.UrlToLink;
              fuseResult.item.ID = 0;
            } else {
              fuseResult.item.url = "".concat(_this3.baseUrl, "/?page_id=").concat(fuseResult.item.ID);
            }
            fuseResultsToReturn.push(fuseResult.item);
            if (fuseResultsToReturn.length === fuseResults.length) {
              console.log('fuseResultsToReturn', fuseResultsToReturn);
              callback(fuseResultsToReturn);
            }
          });
          callback(fuseResultsToReturn);
        },
        render: {
          option_create: function option_create(data, escape) {
            return "<div class=\"create search-dropdown__item search-dropdown__item--find\">".concat(_this3.searchOptions.searchQueryLabel, " <strong>").concat(escape(data.input), "</strong>&hellip;</div>");
          },
          no_results: function no_results() {
            return "<div class=\"no-results search-dropdown__item\">".concat(_this3.searchOptions.noSuggestionFoundLabel, "</div>");
          }
        },
        onFocus: function onFocus() {
          currentTomSelect.clear();
          currentTomSelect.clearOptions();
        }
      });

      /* extracting the redirect url from the add action */
      currentTomSelect.on('item_add', function (value) {
        _this3.redirectUrl = value.indexOf('http') === 0 ? value : "".concat(_this3.baseUrl, "/?s=").concat(value);
      });

      /* Handling submit button */
      var submitButton = searchInput.closest('.wrapper-search-autocomplete').querySelector(_this3.submitSelector);
      if (submitButton) {
        submitButton.addEventListener('click', function (e) {
          e.preventDefault();
          _this3.fireSearch();
        });
      }

      /* Handling option click */
      currentTomSelect.dropdown_content.addEventListener('click', function () {
        _this3.fireSearch();
      });

      /* handling enter press  */
      currentTomSelect.control_input.addEventListener('keydown', function (e) {
        if (e.keyCode === 13) {
          _this3.fireSearch();
        }
      });
      _this3.tomSelectLookup.push(currentTomSelect);
    });
  },
  getWithExpiry: function getWithExpiry(key) {
    var itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    var item = JSON.parse(itemStr);
    var now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    var valueParsed = jQuery.parseJSON(item.value);
    if ("object" === _typeof(valueParsed)) {
      return valueParsed;
    } else {
      return item.value;
    }
  },
  setWithExpiry: function setWithExpiry(key, value, ttl) {
    if ("object" === _typeof(value)) {
      value = JSON.stringify(value);
    }
    var now = new Date();
    var item = {
      value: value,
      expiry: now.getTime() + ttl
    };
    localStorage.setItem(key, JSON.stringify(item));
  }
};

/* on document ready */
document.addEventListener("DOMContentLoaded", function () {
  GlobalSearch.init();
});